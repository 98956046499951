<template>
    <div style="background-color: #fff">
        <div class="pt-3">
            <search @condition="condition" :fields="searchFields">
            </search>
        </div>
        <div class="mt-3">
            <my-table :data="tableData" :fields="showFields">
                <el-table-column fixed="right" slot="operation" width="120"  label="操作">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="primary"
                            @click="testServer(scope.$index,scope.row.id)" v-access="{url:baseUrl,method:'GET'}" icon="el-icon-refresh"></el-button>
                        <el-button
                            size="mini"
                            type="danger"
                            @click="deleteServer(scope.$index,scope.row.id)" v-access="{url:baseUrl,method:'DELETE'}" icon="el-icon-delete"></el-button>
                    </template>
                </el-table-column>
            </my-table>
        </div>
        <div class="pb-3 mt-3">
            <el-pagination
                    @size-change="sizeChange"
                    @current-change="pageChange"
                    :current-page="page.pageNumber"
                    :page-sizes="[10, 15, 30, 50]"
                    :page-size="page.pageSize"
                    :background="true"
                    layout="sizes,prev, pager,next,total"
                    :total="page.total">
            </el-pagination>
        </div>
        <form-dialog @closeFormDialog="submit" :title="form.title" :show="form.show" :fields="form.fields"
                     :rules="form.rules" :data="form.data"></form-dialog>
    </div>
</template>
<script>
    import F from '@/fields/fields';
    import search from "@/components/common/search";
    import MyTable from '@/components/common/my-table';

    export default {
        name: "server",
        components: {
            search,MyTable
        },
        created() {

        },
        mounted() {

        },
        data: function () {
            return {
                baseUrl:'/google/subscribe/server?all=1',
                page: {
                    search: {},
                    sort: {},
                    total: 1,
                    pageSize: 15,
                    pageNumber: 1,
                },
                server: [],
                tableData:[],
                form: {
                    action_name: '',
                    show: false,
                    title: '',
                    data: {},
                    fields: [],
                    rules: {},
                }
            }
        },
        computed: {
            searchFields: function () {
                return  F.search('historyServerItem', 'search');
            },
            showFields: function () {
                return F.show('historyServerItem', 'show');
            },
        },
        methods: {
            condition: function (search) {
                this.page.search = search;
                this.page.pageNumber = 1;
                this.search();
            },
            search: function () {
                this.$axiosServer.get(this.baseUrl, {
                    params: this.page,
                }).then(data => {
                    data.data.mapping&&this.$store.dispatch('setDict',{proxy_subscribe:data.data.mapping});
                    this.page.total = data.data.total;
                    this.page.pageNumber = data.data.pageNumber;
                    this.page.pageSize = data.data.pageSize;
                    this.tableData = data.data.server;
                });
            },
            sortChange: function (column) {
                if (!column.order) return;
                this.page.sort = {prop: column.prop, order: column.order};
                this.search();
            },
            sizeChange: function (size) {
                this.page.pageSize = size;
                this.search();
            },
            pageChange: function (page) {
                this.page.pageNumber = page;
                this.search();
            },
            filterPlatform(value, row) {
                return row.tag === value;
            },
            submit: function (data) {
                if (data.type === 'cancel') {
                    this.form.show = false;
                    return;
                }
                switch (this.actionName) {
                    case 'editUser':
                        this.$axiosServer.put(this.baseUrl+'/' + data.data.user_id, data.data).then(userData => {
                            this.$message.success('保存成功!');
                            this.tableData.splice(this.index,1,userData.data);
                        });
                        break;
                        case 'addUser':
                            this.$axiosServer.post(this.baseUrl,data.data).then(userData => {
                                this.page.total++;
                                this.$message.success('创建成功!');
                                this.tableData.unshift(userData.data);
                            });
                            break;
                }
                this.form.show = false;
            },
            testServer:function (index,id){
                  this.$axiosServer.get('/google/subscribe/test?id='+id).then(data=>{
                      try {
                          let info=JSON.parse(data.data.info);
                          data.data.ps=info.ps;
                      }catch (e) {
                          this.message.warn('解析返回数据失败!');
                          return;
                      }
                      this.$message.success('测试成功!');
                      this.tableData.splice(index,1,data.data);
                  });
            },
            deleteServer: function (index,id) {
                this.$confirm('你真的要删除这个服务器吗?','操作确认').then(()=>{
                   this.$axiosServer.delete(this.baseUrl,{data:{id}}).then(()=>{
                       this.tableData.splice(index, 1);
                       this.page.total--;
                       this.$message.success('删除成功!');
                   });
                });
            }
        }
    }
</script>

<style scoped>

</style>
